import {
  BankDetails,
  BankDetailsResponse,
  ConfirmWithdrawWallet,
  FundWallet,
  ResponseSuccess,
  WalletBalance,
  WalletTransactionHistory,
  WalletWithdrawlInitiateResponse,
  WithdrawWallet
} from '../../TYPES/api.body';
import {
  ADD_BANK_DETAILS,
  CONFIRM_WALLET_WITHDRAWAL,
  CONVERT_POINTS_TO_NAIRA,
  DELETE_BANK_DETAILS,
  FUND_WALLET,
  GET_BANK_DETAILS,
  GET_SELLER_WALLET_BALANCE,
  GET_SELLER_WALLET_HISTORIES,
  GET_SELLER_WALLET_POINTS,
  INITIATE_WALLET_WITHDRAWAL,
  POINT_NAIRA_EQUIVALENT
} from '../config/endpoints';
import api from '../config/setAuthHeader';

export const getSellerWalletBalance = async (): Promise<ResponseSuccess<WalletBalance>> => {
  const { data } = await api.get(GET_SELLER_WALLET_BALANCE);
  return data;
};

export const getSellerWalletPoints = async () => {
  const { data } = await api.get(GET_SELLER_WALLET_POINTS);
  return data;
};

export const getSellerWalletHistories = async (page: number): Promise<ResponseSuccess<WalletTransactionHistory[]>> => {
  const { data } = await api.get(`${GET_SELLER_WALLET_HISTORIES}?page=${page}`);
  return data;
};

export const fundWallet = async (formData: FundWallet) => {
  const { data } = await api.post(FUND_WALLET, formData);
  return data;
};

export const initiateWithdrawWallet = async (formData: WithdrawWallet): Promise<WalletWithdrawlInitiateResponse> => {
  const { data } = await api.post(INITIATE_WALLET_WITHDRAWAL, formData);
  return data;
};

export const confirmWithdrawWallet = async (
  formData: ConfirmWithdrawWallet
): Promise<WalletWithdrawlInitiateResponse> => {
  const { data } = await api.post(CONFIRM_WALLET_WITHDRAWAL, formData);
  return data;
};

export const addBankDetails = async (formData: BankDetails): Promise<ResponseSuccess> => {
  const { data } = await api.post(ADD_BANK_DETAILS, formData);
  return data;
};

export const getBankDetails = async (): Promise<ResponseSuccess<BankDetailsResponse[]>> => {
  const { data } = await api.get(GET_BANK_DETAILS);
  return data;
};

export const deleteBankDetails = async (accountNumber: string): Promise<ResponseSuccess> => {
  const { data } = await api.delete(`${DELETE_BANK_DETAILS}${accountNumber}/delete-account`);
  return data;
};

export const pointNairaEquivalent = async () => {
  const { data } = await api.get(POINT_NAIRA_EQUIVALENT);
  return data;
};

export const convertPointsToNaira = async (point: number) => {
  const { data } = await api.post(CONVERT_POINTS_TO_NAIRA, { point });
  return data;
};
