import { Action } from '../../assets/Icons';
import { OrderStatusCON } from './style';
import { ActionCON } from '../Product/style';
import { formatCurrency } from '../../utils/currencyFormater';
// import { AdminOrderActions } from '../../actions/Admin/Order';
import { formatDate } from '../../utils/dateFormater';
import { TableRowProductCard } from '../../components/molecules/TableRowProductCard';
import { GridRenderCellParams } from '@mui/x-data-grid';

export const filter = [
  {
    label: 'Pending Orders',
    value: 'Pending'
  },

  {
    label: 'Canceled Orders',
    value: 'Canceled'
  },

  {
    label: 'Completed Orders',
    value: 'Completed'
  }
];

export const newOrderColumn = [
  {
    id: 1,
    field: 's/No',
    headerName: 'S/No',
    flex: 1,
    minWidth: 40,
    // renderCell: (params: GridCellParams) => <p>{params.rowNode.id}</p>,
    renderCell: (params: GridRenderCellParams) => params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
  },
  {
    id: 2,
    field: 'orderRef',
    headerName: 'Order ID',
    flex: 1,
    minWidth: 200
  },

  {
    id: 3,
    field: 'name',
    headerName: "Customer's Name",
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{row?.customer?.name}</p>
  },

  {
    id: 4,
    field: 'orderItemQuantity',
    headerName: 'No of Items',
    flex: 1,
    minWidth: 100
  },

  {
    id: 5,
    field: 'orderDate',
    headerName: 'Order Date',
    flex: 2,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{formatDate(row.orderDate)}</p>
  },

  {
    id: 6,
    field: 'price',
    headerName: 'Price',
    flex: 2,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{formatCurrency(row?.totalAmount)}</p>
  },

  {
    id: 7,
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => (
      <OrderStatusCON status={row.status.toLowerCase()}>
        <p>{row.status === 'cancelled' ? 'Canceled' : row.status.toUpperCase()}</p>
      </OrderStatusCON>
    )
  },

  {
    id: 8,
    field: 'deliveryMethod',
    headerName: 'Delivery Type',
    flex: 1,
    minWidth: 100
  }

  // {
  //   id: 7,
  //   field: 'orderType',
  //   headerName: 'Order Type',
  //   flex: 1,
  //   minWidth: 100
  // }

  // {
  //   id: 8,
  //   field: 'action',
  //   headerName: 'Action',
  //   flex: 1,
  //   minWidth: 100,
  //   // renderCell: ({ row }: any) => <OrderActions />
  // }
];

export const orderColumns = [
  {
    id: 1,
    field: 's/No',
    headerName: 'S/No',
    width: 100,
    // renderCell: (params: GridCellParams) => <p>{params.rowNode.id}</p>,
    renderCell: (params: GridRenderCellParams) => params.api.getRowIndexRelativeToVisibleRows(params.row.productId) + 1
  },
  {
    id: 2,
    field: 'productName',
    headerName: 'Product Name',
    flex: 2,
    minWidth: 200,
    renderCell: ({ row }: any) => <TableRowProductCard order={row} />
  },

  {
    id: 3,
    field: 'category',
    headerName: 'Category',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{row?.category.name}</p>
  },

  {
    id: 4,
    field: 'price',
    headerName: 'Price',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{formatCurrency(row?.price)}</p>
  },

  {
    id: 5,
    field: 'quantityAvailable',
    headerName: 'Qty Available',
    flex: 1,
    minWidth: 100
  },

  {
    id: 6,
    field: 'quantitySold',
    headerName: 'Qty Sold',
    flex: 1,
    minWidth: 100
  },

  {
    id: 7,
    field1: 'totalProductAmountSold',
    headerName: 'Total Product Amount Sold',
    flex: 1,
    minWidth: 100
  }

  // {
  //   id: 5,
  //   field: 'totalAmount',
  //   headerName: 'Price',
  //   flex: 2,
  //   minWidth: 100,
  //   renderCell: ({ row }: any) => <p>{formatCurrency(row?.totalAmount)}</p>
  // },

  // {
  //   id: 6,
  //   field: 'deliveryType',
  //   headerName: 'Delivery Type',
  //   flex: 1,
  //   minWidth: 100
  // },

  // {
  //   id: 7,
  //   field: 'status',
  //   headerName: 'Status',
  //   flex: 1,
  //   minWidth: 200,
  //   renderCell: ({ row }: any) => (
  //     <OrderStatusCON status={row.status.toLowerCase()}>
  //       <p>{row.status === 'cancelled' ? 'Canceled' : row.status}</p>
  //     </OrderStatusCON>
  //   )
  // },

  // {
  //   id: 8,
  //   field: 'action',
  //   headerName: 'Action',
  //   flex: 1,
  //   minWidth: 100,
  //   renderCell: ({ row }: any) => <AdminOrderActions status={row.status} order={row.id} />
  // }
];

export const completedOrderColumn = [
  {
    id: 1,
    field: 'orderRef',
    headerName: 'Order ID',
    flex: 1,
    minWidth: 150
  },

  {
    id: 2,
    field: 'name',
    headerName: "Customer's Name",
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{row?.customer?.name}</p>
  },

  {
    id: 3,
    field: 'orderItemQuantity',
    headerName: 'No of Items',
    flex: 1,
    minWidth: 100
  },

  {
    id: 4,
    field: 'orderDate',
    headerName: 'Order Date',
    flex: 1,
    minWidth: 100
  },

  {
    id: 5,
    field: 'price',
    headerName: 'Price',
    flex: 1,
    minWidth: 100
  },

  {
    id: 6,
    field: 'deliveryType',
    headerName: 'Delivery Type',
    flex: 1,
    minWidth: 100
  },

  {
    id: 4,
    field: 'timeTaken',
    headerName: 'Time Taken',
    flex: 2,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{formatDate(row.statusDate)}</p>
  },

  {
    id: 8,
    field: 'action',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => (
      <ActionCON>
        <Action />
      </ActionCON>
    )
  }
];

export const orders = [
  {
    id: 64,
    customer: {
      id: 102,
      name: 'Sec'
    },
    orderRef: '#MACRGA5G0U',
    paymentTrxRef: 'trx5y5y5yyyyy5y5',
    orderItemQuantity: 10,
    address: {
      id: 47,
      firstname: 'Seds ',
      lastname: 'Seds ',
      phoneNumber: '08121346557',
      street: 'Wuse',
      state: 'Abujat',
      country: 'Nigeria ',
      status: null
    },
    totalAmount: 10.0,
    status: 'pending',
    paymentType: 'Credit Card',
    paymentStatus: 'Pending',
    orderDate: '2023-11-30T06:36:35.659494',
    deliveryMethod: 'delivery',
    orderReturned: false
  },
  {
    id: 67,
    customer: {
      id: 102,
      name: 'Sec'
    },
    orderRef: '#MAE70GEOZC',
    paymentTrxRef: 'T567412128755733',
    orderItemQuantity: 10,
    address: {
      id: 47,
      firstname: 'Seds ',
      lastname: 'Seds ',
      phoneNumber: '08121346557',
      street: 'Wuse',
      state: 'Abujat',
      country: 'Nigeria ',
      status: null
    },
    totalAmount: 4220.0,
    status: 'pending',
    paymentType: 'Credit Card',
    paymentStatus: 'success',
    orderDate: '2023-12-04T03:37:39.099321',
    deliveryMethod: 'delivery',
    orderReturned: false
  },
  {
    id: 69,
    customer: {
      id: 102,
      name: 'Sec'
    },
    orderRef: '#MAKS35VZ9U',
    paymentTrxRef: 'T987887919315967',
    orderItemQuantity: 10,
    address: {
      id: 47,
      firstname: 'Seds ',
      lastname: 'Seds ',
      phoneNumber: '08121346557',
      street: 'Wuse',
      state: 'Abujat',
      country: 'Nigeria ',
      status: null
    },
    totalAmount: 10.0,
    status: 'pending',
    paymentType: 'Credit Card',
    paymentStatus: 'success',
    orderDate: '2023-12-04T03:47:58.90163',
    deliveryMethod: 'delivery',
    orderReturned: false
  },
  {
    id: 71,
    customer: {
      id: 102,
      name: 'Sec'
    },
    orderRef: '#MA0OXX40UN',
    orderItemQuantity: 10,
    address: {
      id: 47,
      firstname: 'Seds ',
      lastname: 'Seds ',
      phoneNumber: '08121346557',
      street: 'Wuse',
      state: 'Abujat',
      country: 'Nigeria ',
      status: null
    },
    totalAmount: 10.0,
    status: 'pending',
    paymentType: 'Madina',
    paymentStatus: 'Pending',
    orderDate: '2023-12-04T17:54:42.188988',
    deliveryMethod: 'delivery',
    orderReturned: false
  }
];
