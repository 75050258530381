import { Dispatch, SetStateAction } from 'react';
import { useQueryClient } from 'react-query';
import { WalletTransactionHistory } from '../../../TYPES/api.body';
import { Message } from './Message';
import { NavButton, NavigationCon } from './styles';

type MessageListProp = {
  data: WalletTransactionHistory[];
  setValue: Dispatch<SetStateAction<number>>;
  value: number;
  totalPages: number;
};

export const MessageList = ({ data, setValue, value, totalPages }: MessageListProp) => {
  const queryClient = useQueryClient();
  return (
    <div className="list-con">
      {/* {data.sub && <h3>{data.sub}</h3>} */}
      {data.map((mes, index) => (
        <Message data={mes} key={index} />
      ))}
      <NavigationCon>
        <NavButton
          hidden={Number(value) === 0}
          onClick={() => {
            setValue(value - 1);
            // goto(`/account/wallet?page=${Number(value) - 1}`);
            queryClient.invalidateQueries('wallet_histories');
          }}>
          Previous
        </NavButton>

        <NavButton
          hidden={Number(value) === totalPages - 1}
          onClick={() => {
            setValue(value + 1);
          }}>
          Next
        </NavButton>
      </NavigationCon>
    </div>
  );
};
